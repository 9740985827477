import { Sadface, CloseIcon , Happyface , HalfDed} from "./IconLoader";

const Modal = ({closeModal , originalWord , reset , status}) => {
  return (
    <>
      <dialog open={true} className="w-[90%] sm:w-[60%] md:w-[60%] min-h-[350px]  bg-white rounded z-10 fixed top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 p-6">
        <div onClick={closeModal} className="float-right cursor-pointer">
            <CloseIcon />
        </div>
        <div className="flex flex-col justify-end items-center w-full h-[calc(100%-24px)] gap-6">
          <h1 className="text-3xl">{status === 'fail' ? 'Loser!!!' : 'Winner!!'}</h1>
            {status === 'fail' && <Sadface />}
            {status === 'half' && <HalfDed />}
            {status === '' && <Happyface  />}
          <p className="text-medium text-center">
            {status === 'fail' ? <>The word was: "<span>{originalWord}</span>". <br /> Better luck next time. </> : <>Congratulations! You get 1 more point. Keep it going!</>}
          </p>
          <button onClick={reset} className="w-full bg-blue-500 rounded py-3 text-white font-bold text-2xl">
            {status === 'fail' ? <>Run it back!</> : <>Let's go!</>}
          </button>
        </div>
      </dialog>
      <div onClick={closeModal} className="w-full h-full bg-black opacity-20 fixed"></div>
    </>
  );
};

export default Modal;
