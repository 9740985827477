const Dude = ({ counter }) => {
  return (
    <svg
      width="215"
      height="177"
      viewBox="0 0 215 377"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1_2)">
        <rect width="215" height="377" fill="white" />
        <path
          d="M163 122C163 119.239 160.761 117 158 117C155.239 117 153 119.239 153 122H163ZM153 122V243H163V122H153Z"
          fill={counter > 3 ? 'black' : 'none'} //TORSO
        />
        <path
          d="M126.353 307.155C125.334 309.721 126.588 312.628 129.155 313.647C131.721 314.666 134.628 313.412 135.647 310.845L126.353 307.155ZM153.353 239.155L126.353 307.155L135.647 310.845L162.647 242.845L153.353 239.155Z"
          fill={counter > 4 ? 'black' : 'none'} //LEFT LEG
        />
        <path
          d="M107.56 203.7C106.29 206.152 107.248 209.169 109.7 210.44C112.152 211.71 115.169 210.752 116.44 208.3L107.56 203.7ZM150.56 120.7L107.56 203.7L116.44 208.3L159.44 125.3L150.56 120.7Z"
          fill={counter > 6 ? 'black' : 'none'} //LEFT ARM
        />
        <path
          d="M198.569 205.824C199.782 208.027 203.065 209.048 205.9 208.106C208.736 207.163 210.051 204.614 208.837 202.412L198.569 205.824ZM153.219 123.514L198.569 205.824L208.837 202.412L163.487 120.101L153.219 123.514Z"
          fill={counter > 7 ? 'black' : 'none'} //RIGHT ARM
        />
        <path
          d="M163.338 311.988C163.737 314.72 166.276 316.612 169.008 316.213C171.741 315.814 173.632 313.275 173.233 310.543L163.338 311.988ZM173.233 310.543L162.796 239.066L152.901 240.511L163.338 311.988L173.233 310.543Z"
          fill={counter > 5 ? 'black' : 'none'} //RIGHT LEG
        />
        <path
          d="M8 366C16.7596 357.667 39.2255 341 59.012 341C78.7984 341 97.915 355.179 105 362.269"
          stroke={counter > 0 ? 'black' : 'none'} //STAND
          strokeWidth="10"
          strokeLinecap="round"
        />
        <path
          d="M57 341V10H151V48.0342"
          stroke={counter > 1 ? 'black' : 'none'} // UPPER STAND
          strokeWidth="10"
          strokeLinecap="round"
        />
        <circle
          cx="158.5"
          cy="79.5"
          r="42.5"
          fill={counter > 2 ? 'white' : 'none'} //HEAD
          stroke={counter > 2 ? 'black' : 'none'}
          strokeWidth="10"
        />
        
        <path //FROM HERE ARE THE HEAD ELEMENTS
          d="M142.786 76.5356L152.046 87.5106"
          stroke={counter > 8 ? 'black' : 'none'}
          strokeWidth="6"
          strokeLinecap="round"
        />
        <path
          d="M152.904 77.3931L141.929 86.6532"
          stroke={counter > 8 ? 'black' : 'none'}
          strokeWidth="6"
          strokeLinecap="round"
        />
        <path
          d="M175.539 79.2794L184.8 90.2543"
          stroke={counter > 8 ? 'black' : 'none'}
          strokeWidth="6"
          strokeLinecap="round"
        />
        <path
          d="M185.657 80.1368L174.682 89.3969"
          stroke={counter > 8 ? 'black' : 'none'}
          strokeWidth="6"
          strokeLinecap="round"
        />
        <path
          d="M148 100C152.391 97.7361 163.739 94.5667 174 100"
          stroke={counter > 8 ? 'black' : 'none'}
          strokeWidth="6"
          strokeLinecap="round"
        />
      </g>
    </svg>
  );
};

export default Dude;
