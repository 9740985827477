export const requestWord = async (lang) => {

    let url = 'https://random-word-api.herokuapp.com/word'

    if(lang === 'esp') {
        url = url + '?lang=es'
    }

    const response = await fetch(url);
    const data = await response.json();
    return [...data[0]]
}