import { useEffect, useState } from "react";
import Word from "./components/Word";
import Header from "./components/Header";
import Keyboard from "./components/Keyboard";
import Footer from "./components/Footer";
import Dude from "./components/Dude";
import Modal from "./components/Modal";
function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [counter, setCounter] = useState(0);
  const [language, setLanguage] = useState();
  const [letter, setLetter] = useState("");
  const [keyArr, setKeyArr] = useState([]);
  const [wrongletterArr, setWrongLetterArr] = useState([]);
  const [wrongLetterCounter, setWrongLetterCounter] = useState(0);
  const [modal, setModal] = useState(false);
  const [originalWord, setOriginalWord] = useState();
  const [status, setStatus] = useState();
  const [victoryCounter, setVictoryCounter] = useState(0);
  const [keyboardLetter, setKeyboardLetter] = useState('');

  const languageHandler = (language) => {
    if (language === "esp") {
      setLanguage("esp");
    } else setLanguage();
  };
  const getLetterToFilter = (e, type) => {
    if (type === "keyboard") {
      setLetter(e);
      setKeyArr((item) => (item = [...item, e]));
    } else {
      setLetter(e.target.dataset.id);
      setKeyArr((item) => (item = [...item, e.target.dataset.id]));
    }
  };
  const wrontLetterHandler = (e) => {
    setWrongLetterArr((prev) => (prev = [...prev, e]));
    setWrongLetterCounter((prev) => (prev = prev + 1));
    if (wrongLetterCounter === 8) {
      fateStatus("fail");
    }
  };

  const fateStatus = (value) => {
    if (value === "fail") {
      setStatus(value);
      setModal(true);
    } else if(wrongLetterCounter > 7) {
      setStatus('half');
      setModal(true);
    } else {
      setStatus();
      setModal(true);
    }
  };

  const resetHandler = () => {
    setCounter((prev) => (prev = prev + 1));
    setKeyArr((item) => (item = []));
    setWrongLetterArr((item) => (item = []));
    setWrongLetterCounter((prev) => (prev = 0));
    if (modal) {
      setModal(false);
    }
    if (keyArr.length !== 0 && status === "fail") {
      setVictoryCounter((item) => (item = item - 1));
    } else if (keyArr.length !== 0 && status === undefined) {
      setVictoryCounter((item) => (item = item + 1));
    }
    setStatus();
  };

  const sendOriginalWordHandler = (e) => {
    setOriginalWord(e);
  };

  useEffect(() => {
    document.body.addEventListener("keydown", function (event) {
      var key = event.key.toLowerCase();
      setKeyboardLetter(key)
    });
  },[]);

  useEffect(() => {
    if(keyArr.includes(keyboardLetter)) {
      return
    } else {
      getLetterToFilter(keyboardLetter, 'keyboard')
    }
  }, [keyboardLetter])

  return (
    <div className=" ">
      {modal ? (
        <Modal
          status={status}
          reset={resetHandler}
          originalWord={originalWord}
          closeModal={() => setModal(false)}
        />
      ) : (
        ""
      )}
      <Header lang={languageHandler} />
      <div className="gap-12 sm:gap-4 container mx-auto px-6 min-h-[calc(100vh-200px)] flex flex-col justify-between py-6">
        <div className="flex justify-center items-center">
          <Dude counter={wrongLetterCounter} />
        </div>
        <Word
          sendVictory={fateStatus}
          sendOriginalWord={sendOriginalWordHandler}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          wrongLetter={wrontLetterHandler}
          letterToFilter={letter}
          setLang={language}
          changeWord={counter}
        />
        <Keyboard
          isLoading={isLoading}
          keyboardEvent={getLetterToFilter}
          actualLang={language}
          keysArr={keyArr}
          wrongLetters={wrongletterArr}
        />
        <div className="w-full flex items-center justify-center">
          <button
            className="py-3 px-12 text-3xl text-white border-blue-500 border-2 bg-blue-400"
            onClick={resetHandler}
          >
            Change Word
          </button>
        </div>
        <h3 className="text-center text-3xl">
          You have: {victoryCounter} points.
        </h3>
      </div>
      <Footer />
    </div>
  );
}

export default App;
