const Footer = () => {
  return (
    <footer className="w-full min-h-[100px] flex flex-col justify-center items-center bg-blue-500 text-white py-3">
        <div className="container mx-auto px-6 flex flex-col md:flex-row justify-between items-center">
            <h1 className="text-2xl font-bold">The Hangman App</h1>
            <p className="text-center">
            Powered by: 
                <a className="underline" href="http://random-word-api.herokuapp.com/home">
                {' http://random-word-api.herokuapp.com/home'}
                </a>
            </p>
        </div>
        <div className="w-full container mx-auto px-6">
            <p className="text-center text-2xl">Developed by: <a className="underline" href="https://www.danielq.dev">danielq.dev</a></p>
        </div>
    </footer>
  )
}

export default Footer
