const Header = ({ lang }) => {
    const notReady = false;
  return (
    <header className="w-full h-[100px] bg-blue-500 flex flex-col justify-center items-center">
      <div className="text-white container mx-auto flex items-center justify-between px-6">
        <h1 className="text-3xl font-bold">The Hangman App</h1>
        <div className="flex flex-col">
        {notReady ? <><p>Select Language: </p>
          <div className="flex justify-between">
             <button className="text-2xl" onClick={() => lang("esp")}>
              Esp
            </button>
            <button className="text-2xl" onClick={() => lang()}>
              Eng
            </button> 
          </div></> : ''}
        </div>
      </div>
    </header>
  );
};

export default Header;
