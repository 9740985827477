import { useEffect, useState } from "react";
import { requestWord } from "../services";

const Word = ({
  setLang,
  changeWord,
  letterToFilter,
  wrongLetter,
  isLoading,
  setIsLoading,
  sendOriginalWord,
  sendVictory,
}) => {
  const [word, setWord] = useState([]);
  const [firstTime, setFirstTime] = useState(false);
  const [originalWord, setOriginalWord] = useState([]);
  const [arrayOfLetters, setArrayOfLetters] = useState([]);
  const [secondCheck, setSecondCheck] = useState(false);

  useEffect(() => {
    let mounted = true;
    setIsLoading(true);
    setArrayOfLetters([]);
    requestWord(setLang).then((data) => {
      if (mounted) {
        let changedData = data;
        setOriginalWord(data);
        let newString = [];
        for (let i = 0; changedData.length > i; i++) {
          newString.push("_");
        }
        setArrayOfLetters([]);
        setWord(newString);
        setFirstTime(true);
        setIsLoading(false);
      }
    });
    return () => {
      return (mounted = false);
    };
  }, [setLang, changeWord]);

  useEffect(() => {
    if (firstTime === true) {
      sendOriginalWord(originalWord);
      if (!originalWord.includes(letterToFilter)) {
        wrongLetter(letterToFilter);
        return;
      } else if (
        originalWord.includes(letterToFilter) &&
        !arrayOfLetters.includes(letterToFilter)
      ) {
        setArrayOfLetters((prev) => {
          return [...prev, letterToFilter];
        });
      }
      setSecondCheck(true);
    }
  }, [letterToFilter]);

  useEffect(() => {
    if (secondCheck === true) {
      let ogData = [...originalWord];
      for (let i = 0; ogData.length > i; i++) {
        if (!arrayOfLetters.includes(ogData[i])) {
          ogData[i] = "_";
        }
        setWord((prevArr) => (prevArr = ogData));
      }
      setSecondCheck(false);
    }
  }, [arrayOfLetters, secondCheck]);

  useEffect(() => {
    if (word.includes("_") === false && letterToFilter !== '') {
      sendVictory();
    }
  }, [word]);

  return (
    <div>
      {isLoading ? (
        <h2 className="text-center font-bold text-6xl text-green-500">
          Loading...
        </h2>
      ) : (
        ""
      )}
      {!isLoading ? (
        <h1 className="text-center font-bold tracking-[.5rem] text-3xl md:tracking-[1rem] md:text-6xl">
          {word.join("")}
        </h1>
      ) : (
        ""
      )}
    </div>
  );
};

export default Word;
