import { useEffect, useState } from "react";
import { keyboard } from "../utils";
const Keyboard = ({
  actualLang,
  keyboardEvent,
  keysArr,
  wrongLetters,
  isLoading,
}) => {
  const [currentKeyboard, setCurrentKeyboard] = useState([]);
  useEffect(() => {
    if (actualLang !== "esp") {
      setCurrentKeyboard(() => {
        return keyboard.filter((item) => item !== "ñ");
      });
    } else {
      setCurrentKeyboard(keyboard);
    }
  }, [actualLang]);
  return (
    <div
      className={`max-w-[600px] mx-auto grid grid-cols-7 sm:grid-cols-12 gap-2 text-2xl`}
    >
      {currentKeyboard.map((item) => (
        <button
          disabled={keysArr.includes(item) || isLoading}
          onClick={keyboardEvent}
          data-id={item}
          className={`p-2 border-2 border-blue-500 text-blue-500 ${
            wrongLetters !== undefined && wrongLetters.includes(item)
              ? "disabled:border-red-500 disabled:text-red-500 disabled:bg-red-100"
              : "disabled:border-green-500 disabled:text-green-500 disabled:bg-green-100"
          } ${
            isLoading && wrongLetters === undefined
              ? "disabled:border-gray-500 disabled:text-gray-500"
              : ""
          }`}
          key={item}
        >
          {item}
        </button>
      ))}
    </div>
  );
};

export default Keyboard;
