export const Sadface = () => {
  return (
    <svg
      className="w-20 h-20"
      width="512"
      height="512"
      viewBox="0 0 512 512"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="512" height="512" fill="white" />
      <path
        d="M479.387 256.5C479.387 379.597 379.597 479.387 256.5 479.387C133.403 479.387 33.6133 379.597 33.6133 256.5C33.6133 133.403 133.403 33.6133 256.5 33.6133C379.597 33.6133 479.387 133.403 479.387 256.5Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M256.5 57.4884C146.589 57.4884 57.4884 146.589 57.4884 256.5C57.4884 366.411 146.589 455.512 256.5 455.512C366.411 455.512 455.512 366.411 455.512 256.5C455.512 146.589 366.411 57.4884 256.5 57.4884ZM12 256.5C12 121.466 121.466 12 256.5 12C391.534 12 501 121.466 501 256.5C501 391.534 391.534 501 256.5 501C121.466 501 12 391.534 12 256.5Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M167.285 233.459C172.03 229.493 179.122 230.089 183.126 234.789L230.886 290.865C234.89 295.565 234.289 302.591 229.544 306.557C224.799 310.523 217.707 309.928 213.703 305.227L165.943 249.152C161.939 244.451 162.54 237.425 167.285 233.459Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M236.224 237.682C240.326 242.609 239.711 249.974 234.848 254.131L176.839 303.729C171.976 307.886 164.708 307.262 160.605 302.335C156.502 297.407 157.118 290.043 161.981 285.885L219.99 236.288C224.853 232.13 232.121 232.754 236.224 237.682Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M337.643 246.968C342.57 243.002 349.935 243.597 354.093 248.297L403.69 304.373C407.848 309.074 407.224 316.099 402.296 320.066C397.369 324.032 390.004 323.436 385.846 318.736L336.249 262.66C332.091 257.959 332.716 250.934 337.643 246.968Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M406.519 253.738C410.485 258.483 409.889 265.575 405.189 269.579L349.113 317.339C344.412 321.343 337.387 320.742 333.421 315.997C329.455 311.252 330.05 304.16 334.751 300.156L390.826 252.396C395.527 248.392 402.553 248.993 406.519 253.738Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M332.907 372.718C283.594 347.162 228.22 362.067 206.998 372.775C201.381 375.609 194.48 373.45 191.584 367.952C188.688 362.455 190.894 355.7 196.511 352.866C221.399 340.308 285.18 322.64 343.617 352.923C349.202 355.817 351.332 362.595 348.375 368.061C345.417 373.527 338.492 375.612 332.907 372.718Z"
        fill="black"
      />
    </svg>
  );
};

export const CloseIcon = () => {
  return (
    <div className="w-[25px]">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className={`w-full`}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M6 18L18 6M6 6l12 12"
        />
      </svg>
    </div>
  );
};

export const Happyface = () => {
  return (
    <svg
      className="w-20 h-20"
      width="512"
      height="512"
      viewBox="0 0 512 512"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="512" height="512" fill="white" />
      <path
        d="M479.387 256.5C479.387 379.597 379.597 479.387 256.5 479.387C133.403 479.387 33.6133 379.597 33.6133 256.5C33.6133 133.403 133.403 33.6133 256.5 33.6133C379.597 33.6133 479.387 133.403 479.387 256.5Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        cliprule="evenodd"
        d="M256.5 57.4884C146.589 57.4884 57.4884 146.589 57.4884 256.5C57.4884 366.411 146.589 455.512 256.5 455.512C366.411 455.512 455.512 366.411 455.512 256.5C455.512 146.589 366.411 57.4884 256.5 57.4884ZM12 256.5C12 121.466 121.466 12 256.5 12C391.534 12 501 121.466 501 256.5C501 391.534 391.534 501 256.5 501C121.466 501 12 391.534 12 256.5Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M192.8 338.304C242.113 363.86 297.487 348.955 318.709 338.247C324.326 335.413 331.227 337.572 334.123 343.07C337.019 348.567 334.813 355.322 329.196 358.156C304.308 370.714 240.527 388.383 182.09 358.099C176.505 355.205 174.375 348.427 177.332 342.961C180.29 337.495 187.215 335.41 192.8 338.304Z"
        fill="black"
      />
      <circle cx="169" cy="233" r="30" fill="black" />
      <circle cx="342" cy="233" r="30" fill="black" />
    </svg>
  );
};

export const HalfDed = () => {
  return (
    <svg
    className="w-20 h-20"
      width="512"
      height="512"
      viewBox="0 0 512 512"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="512" height="512" fill="white" />
      <path
        d="M479.387 256.5C479.387 379.597 379.597 479.387 256.5 479.387C133.403 479.387 33.6133 379.597 33.6133 256.5C33.6133 133.403 133.403 33.6133 256.5 33.6133C379.597 33.6133 479.387 133.403 479.387 256.5Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M256.5 57.4884C146.589 57.4884 57.4884 146.589 57.4884 256.5C57.4884 366.411 146.589 455.512 256.5 455.512C366.411 455.512 455.512 366.411 455.512 256.5C455.512 146.589 366.411 57.4884 256.5 57.4884ZM12 256.5C12 121.466 121.466 12 256.5 12C391.534 12 501 121.466 501 256.5C501 391.534 391.534 501 256.5 501C121.466 501 12 391.534 12 256.5Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M180.383 318.308C234.827 329.294 283.956 299.715 301.407 283.577C306.026 279.305 313.255 279.48 317.553 283.968C321.852 288.455 321.591 295.556 316.972 299.827C296.505 318.754 240.056 353.305 175.54 340.287C169.373 339.043 165.459 333.114 166.796 327.045C168.134 320.975 174.217 317.064 180.383 318.308Z"
        fill="black"
      />
      <circle cx="166" cy="203" r="30" fill="black" />
      <circle cx="347" cy="243" r="30" fill="black" />
    </svg>
  );
};
